import React, { FC, useEffect, useRef, useState } from 'react'

import { styled } from '@linaria/react'

import { applyIfAnimationsEnabledCss } from 'components/designSystem/styles/applyIfAnimationsEnabledCss'
import { useChangeKeyOnWidthResize } from 'components/designSystem/Wave/Wave.hooks'

export const ProgressBar: FC<{
  progress: number
}> = ({ progress }) => {
  const [container, setContainer] = useState<HTMLDivElement | null>(null)
  const key = useChangeKeyOnWidthResize()

  return (
    <ProgressBarContainer key={key} ref={setContainer} data-name="progress-bar">
      {container && (
        <>
          <ProgressBarInner container={container} />
          <ProgressBarInner container={container} progress={progress} />
        </>
      )}
    </ProgressBarContainer>
  )
}

const ProgressBarInner: FC<{
  container: HTMLDivElement
  progress?: number
}> = ({ container, progress }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    if (!canvasRef.current) {
      return
    }

    const canvas = canvasRef.current
    const ctx = canvas.getContext('2d')!
    const amplitude = 7
    const frequency = 0.05
    const yOffset = canvas.height / 2
    const lineWidth = 7

    const drawSinusoidalWave = async () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      ctx.beginPath()
      ctx.moveTo(0, yOffset)

      for (let x = 0; x < canvas.width; x += 2) {
        const y = yOffset - amplitude * Math.sin(x * frequency)
        ctx.lineTo(x, y)
      }

      ctx.strokeStyle = progress !== undefined ? '#2E2A29' : '#7F74723D'
      ctx.lineWidth = lineWidth
      ctx.stroke()
    }

    drawSinusoidalWave()
  }, [progress])

  const scaleTimes = 3

  return (
    <CanvasWrapper
      {...(progress !== undefined
        ? { style: { width: 100 * progress + '%', zIndex: 1 } }
        : undefined)}
    >
      <Canvas
        ref={canvasRef}
        height={container.clientHeight! * scaleTimes}
        width={container.clientWidth! * scaleTimes}
      />
    </CanvasWrapper>
  )
}

const ProgressBarContainer = styled.div`
  position: relative;
  display: flex;
  height: 20px;
  width: 100%;
`
const CanvasWrapper = styled.div`
  position: absolute;
  height: 20px;
  width: 100%;
  overflow: hidden;
  ${applyIfAnimationsEnabledCss(`
    transition: width 700ms;
    transition-delay: 250ms;
  `)}
`
const Canvas = styled.canvas`
  position: absolute;
  height: 20px;

  ${applyIfAnimationsEnabledCss(`
    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    animation: fadeIn 1s;
  `)}
`
