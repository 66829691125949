import { styled } from '@linaria/react'

import { PrimaryButton } from 'components/designSystem/Button/PrimaryButton'

import { LoginVariantText } from './LoginVariantText'

export const Link = styled.a`
  text-decoration: none;
  cursor: pointer;
`
export const ListItemInner = styled.div`
  display: inline-grid;
  grid-auto-flow: column;
  align-items: center;
  grid-gap: var(--spacing-8px, 8px);
  gap: var(--spacing-8px, 8px);
`
export const LinkInline = styled.a``
export const LoginOptionTextWhite = styled(LoginVariantText)`
  position: relative;
  top: 1px;
  color: var(--accent-primary-on-accent-primary, #f2f5f7);
`
export const ButtonLink = styled(PrimaryButton)`
  svg {
    width: 24px;
    height: 24px;
  }
`
export const ButtonInner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & > * + * {
    html[dir='ltr'] & {
      margin-left: var(--spacing-8px, 8px);
    }
    html[dir='rtl'] & {
      margin-right: var(--spacing-8px, 8px);
    }
  }
`
