import React, { FC } from 'react'

import { styled } from '@linaria/react'

import { WithChildren } from 'common/types'
import { formaDJRCyrillicMicroFontFamily } from 'components/designSystem/shared/fonts/formaDJRCyrillic/formaDJRCyrillicFontFamily'
import { UnshapedCircleSvg } from 'components/designSystem/svgr/UnshapedCircleSvg'
import { Typography } from 'components/designSystem/Typography/Typography'

export const OrderedItem: FC<{} & WithChildren> = ({ children }) => {
  return (
    <OrderedListItem>
      <Counter>
        <StyledUnshapedCircle />
      </Counter>
      <OrderedItemInner fontSize={16}>{children}</OrderedItemInner>
    </OrderedListItem>
  )
}
const StyledUnshapedCircle = styled(UnshapedCircleSvg)``
const Counter = styled.div`
  width: 32px;
  height: 32px;
  position: relative;

  &::after {
    position: absolute;
    content: counter(custom-counter) '';
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--pearl-on-pearl, #2e2a29);
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.32px;
    font-family: ${formaDJRCyrillicMicroFontFamily};
  }
`
const OrderedItemInner = styled(Typography)`
  html[dir='ltr'] & {
    text-align: left;
  }
  html[dir='rtl'] & {
    text-align: right;
  }
`
const OrderedListItem = styled.li`
  width: 100%;
  display: flex;
  align-items: baseline;
  counter-increment: custom-counter;

  & > * + * {
    html[dir='ltr'] & {
      margin-left: var(--spacing-12px, 12px);
    }
    html[dir='rtl'] & {
      margin-right: var(--spacing-12px, 12px);
    }
  }

  ${OrderedItemInner} {
    align-self: center;
  }
`
