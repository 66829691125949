import React, { FC } from 'react'

import { styled } from '@linaria/react'
import { FormattedMessage } from 'react-intl'

import { BottomSheetContentLayout } from 'components/designSystem/BottomSheet/BottomSheetContentLayout'
import { OrderedItem } from 'components/designSystem/OrderedList/OrderedItem'
import { OrderedList } from 'components/designSystem/OrderedList/OrderedList'
import { Typography } from 'components/designSystem/Typography/Typography'

export const NameInvalidReasons: FC = () => {
  return (
    <BottomSheetContentLayout>
      <Description fontSize={16} fontWeight={700}>
        <FormattedMessage
          id="app.you_name_cannot_contain"
          defaultMessage="Твое имя не может содержать:"
        />
      </Description>

      <OrderedList>
        <OrderedItem>
          <FormattedMessage
            id="app.name_rejected_info_1"
            defaultMessage="Мат, хамство, оскорбления."
          />
        </OrderedItem>
        <OrderedItem>
          <FormattedMessage
            id="app.name_rejected_info_2"
            defaultMessage="Предложение коммерческих услуг или секса."
          />
        </OrderedItem>
        <OrderedItem>
          <FormattedMessage
            id="app.name_rejected_info_3"
            defaultMessage="Попытки выдать себя за администратора приложения."
          />
        </OrderedItem>
        <OrderedItem>
          <FormattedMessage
            id="app.name_rejected_info_4"
            defaultMessage="Отсутствие смысловой нагрузки."
          />
        </OrderedItem>
      </OrderedList>
    </BottomSheetContentLayout>
  )
}

const Description = styled(Typography)`
  text-align: center;
`
