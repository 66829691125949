import React, { FC } from 'react'

import loadable from '@loadable/component'

import flyingBirdSvg from 'components/designSystem/svg/flying-bird.svg'
import { useIsIosVersionWithSvgAnimationIssues } from 'hooks/useIsIosVersionWithSvgAnimationIssues'

export const FlyingBirdAnimation: FC = () => {
  const iosVersionWithSvgAnimationIssues = useIsIosVersionWithSvgAnimationIssues()

  if (iosVersionWithSvgAnimationIssues) {
    return (
      <div style={{ width: 200, height: 200 }}>
        <FlyingBirdAnimationLottieLoadable />
      </div>
    )
  }

  return (
    <img
      src={flyingBirdSvg}
      width={200}
      height={200}
      alt="flying-bird"
      draggable={false}
      style={{ userSelect: 'none' }}
    />
  )
}

const FlyingBirdAnimationLottieLoadable = loadable(() =>
  import('./FlyingBirdAnimationLottie')
)
