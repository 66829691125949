import React, { FC, useMemo, useState } from 'react'

import { BannerPopUpRulesCookie } from 'components/banner/BannerPopUpRulesCookie'
import {
  OnboardingLayoutInner,
  OnboardingLayoutWrapper,
} from 'components/designSystem/layout/OnboardingLayout/styled'
import { StepSlider } from 'components/designSystem/StepSlider/StepSlider'
import { BaseUrlContext } from 'components/system/baseUrl/BaseUrlContext'
import { useUpdateRedirectAfterAuthPathAndGoToOnboarding } from 'components/system/redirectAfterAuth/useUpdateRedirectAfterAuthPath'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { useLocale } from 'hooks/useLocale'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

import { HeaderDesktop } from './HeaderDesktop'
import { NavigationBar } from './NavigationBar'
import { NextStepButtonPortal } from './NextStepButton'
import { OnboardingContext } from './Onboarding.context'
import { useNavigationSteps } from './Onboarding.hooks'
import { OnboardingWave } from './OnboardingWave'
import { VerticalWave } from './VerticalWave'

export const Onboarding: FC<{
  /** Путь, на который нужно попасть после авторизации */
  redirectAfterAuthPath?: string
}> = ({ redirectAfterAuthPath }) => {
  const locale = useLocale()
  useUpdateRedirectAfterAuthPathAndGoToOnboarding(redirectAfterAuthPath)

  const { animationsDisabled } = useShallowEqualSelector(
    ({ systemReducer: { animationsDisabled } }) => ({
      animationsDisabled,
    })
  )

  const {
    step,
    steps,
    backgrounds,
    loginFlow,
    activeStep,
  } = useNavigationSteps()

  const [
    bottomSheetPortal,
    setBottomSheetPortal,
  ] = useState<HTMLDivElement | null>(null)

  const contextValue = useMemo(
    () => ({
      loginFlow,
      bottomSheetPortal,
    }),
    [bottomSheetPortal, loginFlow]
  )

  return (
    <BaseUrlContext.Provider value={mergeAllUrls(locale)}>
      <OnboardingContext.Provider value={contextValue}>
        <OnboardingLayoutWrapper
          {...(animationsDisabled && {
            style: { backgroundColor: backgrounds[step] },
          })}
        >
          {!animationsDisabled && (
            <>
              <VerticalWave step={step} backgrounds={backgrounds} />
              <OnboardingWave step={step} hidden={activeStep?.waveHidden} />
            </>
          )}

          <OnboardingLayoutInner>
            <StepSlider step={step}>
              {steps.map((Component, index) => (
                <Component key={index} active={step === index} />
              ))}
            </StepSlider>

            <NextStepButtonPortal />

            <NavigationBar step={step} />

            {/** Нужно поднимать BottomSheet по DOM-дереву вверх из вложенных экранов */}
            {/** а также, чтобы верно считался контекст для элементов с zIndex */}
            <div ref={setBottomSheetPortal} />
          </OnboardingLayoutInner>

          <HeaderDesktop visible={step === 0} />
        </OnboardingLayoutWrapper>

        <BannerPopUpRulesCookie />
      </OnboardingContext.Provider>
    </BaseUrlContext.Provider>
  )
}

export default Onboarding
