import React, { FC, useCallback } from 'react'

import { parsePhoneNumber } from 'libphonenumber-js'

import { resetPasswordPhoneNextAction } from 'actions/authorization/resetPasswordAction'
import {
  confirmSmsCodeNextAction,
  updateAuthFieldAction,
} from 'actions/authorizationAction'
import {
  PhoneVerificationAction,
  PhoneVerificationMethod,
} from 'components/page/EnterVerificationCode/constants'
import { Replace } from 'components/system/Replace'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { useReducersInsert } from 'hooks/useReducersInsert'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { counterDownReducer } from 'reducers/utility/counterDown/counterDownReducer'

import { restorePasswordPath } from './paths'
import { EnterVerificationCode } from '../EnterVerificationCode/EnterVerificationCode'

export const RestorePasswordEnterSmsCodeStep: FC<{ active: boolean }> = ({
  active,
}) => {
  useReducersInsert({
    counterDown: counterDownReducer,
  })

  const dispatch = useAppDispatch()

  const {
    phone,
    smsErrorCode,
    resettingPassword,
    locale,
    timer,
  } = useShallowEqualSelector(
    ({
      authorizationReducer: {
        form: { phone },
        smsErrorCode,
        resettingPassword,
      },
      systemReducer: { locale },
      counterDown: { timer },
    }) => ({
      phone,
      smsErrorCode,
      resettingPassword,
      locale,
      timer,
    })
  )

  const handleSubmit = useCallback(
    (code: string) => {
      dispatch(updateAuthFieldAction('smsCode', code))
      dispatch(confirmSmsCodeNextAction())
    },
    [dispatch]
  )

  const handleResendCode = () => {
    dispatch(resetPasswordPhoneNextAction(false))
  }

  if (!phone.phoneNumberFull) {
    return (
      <Replace
        to={mergeAllUrls(locale, restorePasswordPath)}
        uid="RestorePasswordEnterSmsCode-no-phone-number"
      />
    )
  }

  const formattedPhoneNumber = parsePhoneNumber(
    phone.phoneNumberFull
  ).formatInternational()

  return (
    <EnterVerificationCode
      active={active}
      timer={timer}
      phoneVerificationMethod={PhoneVerificationMethod.Sms}
      phoneVerificationAction={PhoneVerificationAction.ResendCode}
      phoneNumber={formattedPhoneNumber}
      codeLength={6}
      disabled={!active}
      error={Boolean(smsErrorCode)}
      loading={resettingPassword}
      onSubmit={handleSubmit}
      onResendCode={handleResendCode}
      backButtonVisible={false}
    />
  )
}
