import React, {
  ChangeEventHandler,
  FC,
  FormEventHandler,
  KeyboardEventHandler,
  useCallback,
} from 'react'

import { styled } from '@linaria/react'
import { FormattedMessage } from 'react-intl'

import { loginAction } from 'actions/authorization/loginAction'
import { updateAuthFieldAction } from 'actions/authorizationAction'
import { PrimaryButton } from 'components/designSystem/Button/PrimaryButton'
import { SecondaryButton } from 'components/designSystem/Button/SecondaryButton'
import { Input } from 'components/designSystem/Input/Input'
import { OnboardingLayout } from 'components/designSystem/layout/OnboardingLayout/OnboardingLayout'
import { resolveFormFieldValue } from 'components/presentational/form'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

import { StepCommonProps } from './types'
import { InputPassword } from '../../designSystem/Input/InputPassword'
import { restorePasswordPath } from '../RestorePassword/RestorePasswordLoadable'

export const LoginStep: FC<StepCommonProps> = ({ active }) => {
  const {
    login,
    password,
    authorizing,
    errorText,
    locale,
  } = useShallowEqualSelector(
    ({
      authorizationReducer: {
        form: { login, password },
        authorizing,
        formError,
      },
      systemReducer: { locale },
    }) => ({
      login,
      password,
      authorizing,
      errorText: formError?.loginError,
      locale,
    })
  )
  const dispatch = useAppDispatch()

  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault()
    dispatch(loginAction())
  }

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === 'Enter') {
      dispatch(loginAction())
    }
  }

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target }) => {
      const { name, value } = resolveFormFieldValue(target)
      dispatch(updateAuthFieldAction(name, value))
    },
    [dispatch]
  )

  const error = Boolean(errorText)

  return (
    <OnboardingLayout
      title={<FormattedMessage id="app.enter" defaultMessage="Войти" />}
    >
      <Form onSubmit={handleSubmit} id="login-form">
        <Input
          name="login"
          type="text"
          value={login}
          placeholder="Email"
          onChange={handleChange}
          required
          disabled={!active || authorizing}
          invalid={error}
        />

        <InputPassword
          value={password}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          disabled={!active || authorizing}
          invalid={error}
          errorText={errorText}
        />

        <LoginButton
          type="submit"
          loading={authorizing}
          disabled={!login || !password}
        >
          <FormattedMessage id="app.enter" defaultMessage="Войти" />
        </LoginButton>
      </Form>
      <ForgotPasswordButton
        to={mergeAllUrls(locale, restorePasswordPath)}
        size="L"
        color="mamba"
        data-name="forgot-password-action"
      >
        <FormattedMessage
          id="app.i_do_not_remember_password"
          defaultMessage="Я не помню пароль"
        />
      </ForgotPasswordButton>
    </OnboardingLayout>
  )
}

const ForgotPasswordButton = styled(SecondaryButton)`
  margin-top: var(--spacing-12px, 12px);
`
const Form = styled.form``
const LoginButton = styled(PrimaryButton)`
  margin-top: var(--spacing-24px, 24px);
`
