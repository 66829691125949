import React, { FC } from 'react'

import { styled } from '@linaria/react'

import { VoidHandler } from 'common/types'
import { buttonResetCss } from 'components/designSystem/styles/buttonResetCss'
import { QuestionSvg } from 'components/designSystem/svgr/QuestionSvg'

export const NameRulesButton: FC<{
  onClick: VoidHandler
}> = ({ onClick }) => {
  return (
    <NameInfoButton onClick={onClick} data-name="name-rules-action">
      <QuestionSvg />
    </NameInfoButton>
  )
}

export const NameInfoButton = styled.button`
  ${buttonResetCss};
  height: 100%;
  position: absolute;
  padding: 0 12px;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
`
