import cookie from 'js-cookie'

import { Cookies } from 'common-constants/Cookies'

/**
 * https://github.com/js-cookie/js-cookie
 * https://github.com/js-cookie/js-cookie/wiki/Frequently-Asked-Questions#expire-cookies-in-less-than-a-day
 */
export const setCookieValue = <T>(
  name: Cookies | string,
  value: T,
  expires: number | Date,
  secure: boolean = true
) => {
  cookie.set(name, JSON.stringify(value), {
    expires,
    secure,
    sameSite: 'Lax',
  })
}
