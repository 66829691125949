import { useEffect } from 'react'

import { COOKIE_BANNER_CLOSE_BUTTON_DATA_NAME } from 'components/banner/BannerPopUpRulesCookie.constants'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

/** Закроем баннер об использовании кук https://youtrack.mamba.ru/issue/M-9019 */
export const useCloseCookiesBanner = (shouldClose: boolean) => {
  const { touch } = useShallowEqualSelector(({ systemReducer: { touch } }) => ({
    touch,
  }))

  useEffect(() => {
    if (touch && shouldClose) {
      // prettier-ignore
      ;(document.querySelector(`[data-name="${COOKIE_BANNER_CLOSE_BUTTON_DATA_NAME}"]`) as HTMLButtonElement)?.click()
    }
  }, [shouldClose, touch])
}
