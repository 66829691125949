import React, { FC } from 'react'

import { styled } from '@linaria/react'
import { FormattedMessage } from 'react-intl'

import { LoginVariants } from 'components/block/LoginVariants/LoginVariants'
import { Typography } from 'components/designSystem/Typography/Typography'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

import { LastAuthMethodButton } from './LastAuthMethodButton'

export const LastAuthMethodBlock: FC<{
  onVendorClick: (vendorName: string) => void
}> = ({ onVendorClick }) => {
  const { vendorsLoaded } = useShallowEqualSelector(
    ({ authorizationReducer: { vendorsLoaded } }) => ({
      vendorsLoaded,
    })
  )

  return (
    <LastAuthBlock>
      <LastAuthMethod fontSize={14}>
        <FormattedMessage
          id="app.last_auth_method"
          defaultMessage="Предыдущий способ входа"
        />
      </LastAuthMethod>

      <LastAuthMethodButton onVendorClick={onVendorClick} />

      <LoginOptionWrapper style={{ opacity: vendorsLoaded ? 1 : 0 }}>
        <OtherLoginVariants fontSize={14}>
          <FormattedMessage
            id="app.other_auth_methods"
            defaultMessage="Другие варианты входа"
          />
        </OtherLoginVariants>

        <LoginVariants inlineVariant onVendorClick={onVendorClick} />
      </LoginOptionWrapper>
    </LastAuthBlock>
  )
}

const LastAuthBlock = styled.div`
  width: 100%;
`
const LoginOptionWrapper = styled.div`
  transition: opacity 0.3s;

  & > * + * {
    margin-top: var(--spacing-16px, 16px);
  }
`
const OtherLoginVariants = styled(Typography)`
  margin-top: var(--spacing-24px, 24px);
`
const LastAuthMethod = styled(Typography)`
  margin-bottom: var(--spacing-16px, 16px);
`
