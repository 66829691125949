import { MouseEvent, useState } from 'react'

import { useDispatch } from 'react-redux'

import { updateLastClickedAuthVendor } from 'actions/authorization/updateLastClickedAuthVendor'
import { VendorSocialList } from 'common-constants/vendorSocialList'
import { useVkConnectHandler } from 'components/system/third-party/vk/useVkConnectHandler'

import { useVendorAppInstalledNotification } from './useVendorAppInstalledNotification'

export const useVendorItem = (vendor: {
  name: string
  url: string
  app: string
  icon: string
  providerName: string
}) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const { name } = vendor
  const [handleVkVendorClick] = useVkConnectHandler()
  const { handleVendorClick } = useVendorAppInstalledNotification()

  const vendorName = name as VendorSocialList

  const handleClick = async (event: MouseEvent<HTMLElement>) => {
    setLoading(true)
    dispatch(updateLastClickedAuthVendor(vendorName))
    await handleVkVendorClick(event, vendorName)
    await handleVendorClick(event, vendorName)
    setLoading(false)
  }

  const isVkConnect = vendorName === VendorSocialList.vkConnect

  return {
    isVkConnect,
    onVendorClick: handleClick,
    loading,
  }
}
