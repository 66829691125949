import React, { ComponentProps, FC } from 'react'

import { FormattedMessage } from 'react-intl'

import { LoginVariants } from 'components/block/LoginVariants/LoginVariants'
import { BottomSheet } from 'components/designSystem/BottomSheet/BottomSheet'
import { BottomSheetOnRoute } from 'components/designSystem/BottomSheet/BottomSheetOnRoute'
import { replace } from 'functions/router'
import { useAppDispatch } from 'hooks/useAppDispatch'

export const LoginVariantsBottomSheet: FC<{
  showOnPath: string
  onCloseReplaceToPath: string
  desktopPortal?: ComponentProps<typeof BottomSheet>['desktopPortal']
  onLoginWithEmailClick?: LoginVariantsProps['onLoginWithEmailClick']
  onVendorClick: LoginVariantsProps['onVendorClick']
  onCreateProfileClick?: LoginVariantsProps['onCreateProfileClick']
}> = ({
  showOnPath,
  desktopPortal,
  onCloseReplaceToPath,
  onLoginWithEmailClick,
  onVendorClick,
  onCreateProfileClick,
}) => {
  const dispatch = useAppDispatch()

  const handleBottomSheetClose = () => {
    dispatch(replace(onCloseReplaceToPath))
  }

  return (
    <BottomSheetOnRoute
      desktopPortal={desktopPortal}
      path={showOnPath}
      title={<FormattedMessage id="app.enter" defaultMessage="Войти" />}
      onClose={handleBottomSheetClose}
    >
      <LoginVariants
        inlineVariant={false}
        onLoginWithEmailClick={onLoginWithEmailClick}
        onVendorClick={onVendorClick}
        onCreateProfileClick={onCreateProfileClick}
      />
    </BottomSheetOnRoute>
  )
}

type LoginVariantsProps = ComponentProps<typeof LoginVariants>
