import React, { FC } from 'react'

import { styled } from '@linaria/react'
import { FormattedMessage } from 'react-intl'

import { setDatingGoalAction } from 'actions/form/stepRegistrationAction'
import { DatingGoals } from 'common-constants/api6.types'
import { ButtonsBlock } from 'components/designSystem/Button/ButtonsBlock'
import { PrimaryButton } from 'components/designSystem/Button/PrimaryButton'
import { OnboardingLayout } from 'components/designSystem/layout/OnboardingLayout/OnboardingLayout'
import { ChatSvg } from 'components/designSystem/svgr/ChatSvg'
import { HeartSvg } from 'components/designSystem/svgr/HeartSvg'
import { LipsSvg } from 'components/designSystem/svgr/LipsSvg'
import { PointWithEyeSvg } from 'components/designSystem/svgr/PointWithEyeSvg'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { push } from 'functions/router'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

import { finalStepPath } from './paths'
import { StepCommonProps } from './types'

export const SelectDatingGoalsStep: FC<StepCommonProps> = () => {
  const dispatch = useAppDispatch()

  const { name, locale } = useShallowEqualSelector(
    ({
      stepRegistration: {
        form: { name },
      },
      systemReducer: { locale },
    }) => ({ name, locale })
  )

  const selectDatingGoal = (value: DatingGoals) => {
    dispatch(setDatingGoalAction(value))
    dispatch(push(mergeAllUrls(locale, finalStepPath)))
  }

  return (
    <OnboardingLayout
      title={
        <FormattedMessage
          id="app.what.do.we.search"
          defaultMessage="{name}, что будем искать?"
          values={{ name: <Name>{name}</Name> }}
        />
      }
      subtitle={
        <FormattedMessage
          id="app.what.do.we.search.info"
          defaultMessage="Покажем с такой же целью.{br}Передумаешь — измени в настройках профиля."
          values={{ br: <br /> }}
        />
      }
    >
      <ButtonsBlock>
        <PrimaryButton
          color="white"
          border={false}
          onClick={() => selectDatingGoal(DatingGoals.SeriousRelationship)}
          data-name="serious-relationship-action"
        >
          <HeartSvg />
          <ButtonText>
            <FormattedMessage
              id="app.serious.relationship"
              defaultMessage="Серьёзные отношения"
            />
          </ButtonText>
        </PrimaryButton>

        <PrimaryButton
          color="white"
          border={false}
          onClick={() => selectDatingGoal(DatingGoals.FriendlyCommunication)}
          data-name="friendly-communication-action"
        >
          <ChatSvg width={24} height={24} />
          <ButtonText>
            <FormattedMessage
              id="app.friendly.communication"
              defaultMessage="Дружеское общение"
            />
          </ButtonText>
        </PrimaryButton>

        <PrimaryButton
          color="white"
          border={false}
          onClick={() => selectDatingGoal(DatingGoals.FlirtationAndDating)}
          data-name="flirtation-and-dating-action"
        >
          <LipsSvg />
          <ButtonText>
            <FormattedMessage
              id="app.flirtation.and.dating"
              defaultMessage="Флирт и свидание"
            />
          </ButtonText>
        </PrimaryButton>

        <PrimaryButton
          color="white"
          border={false}
          onClick={() => selectDatingGoal(DatingGoals.WillDecideWhenMeet)}
          data-name="will-decide-when-meet-action"
        >
          <PointWithEyeSvg />
          <ButtonText>
            <FormattedMessage
              id="app.will.decide.when.meet"
              defaultMessage="Решу, когда встречу"
            />
          </ButtonText>
        </PrimaryButton>
      </ButtonsBlock>
    </OnboardingLayout>
  )
}

export const Name = styled.span`
  word-break: break-all;
`
export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

const ButtonText = styled.div`
  html[dir='ltr'] & {
    margin-left: var(--spacing-8px, 8px);
  }
  html[dir='rtl'] & {
    margin-right: var(--spacing-8px, 8px);
  }
`
