import { useEffect } from 'react'

import { replace } from 'redux-first-history'

import { updateRedirectAfterAuthPath } from 'actions/authorization/updateRedirectAfterAuthPath'
import { redirectAfterAuthPath } from 'components/paths'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

/** Обновляет поле redirectAfterAuthPath в стейте */
export const useUpdateRedirectAfterAuthPathAndGoToOnboarding = (
  /** Например profile/1822037054/app/storefront/gifts/user/1822037054/gift-present */
  path?: string
) => {
  const dispatch = useAppDispatch()
  const { pathname } = useShallowEqualSelector(
    ({
      router: {
        location: { pathname },
      },
    }) => ({ pathname })
  )

  useEffect(() => {
    if (!path) {
      return
    }

    // mergeAllUrls подставит '/' в начало пути, если нужно.
    dispatch(updateRedirectAfterAuthPath(mergeAllUrls(path)))
    /**
     * Возьмем из, например,
     * /ru/select-gender/redirect-after-auth/en/landing/russia/moscow-and-moscow-region/moscow-4400
     * часть пути до куска /redirect-after-auth.
     * Получится: /ru/select-gender
     */
    const pathWithoutRedirectPart = pathname.split(redirectAfterAuthPath)
    dispatch(replace(mergeAllUrls(pathWithoutRedirectPart[0])))
  }, [dispatch, pathname, path])
}
