import React, { FC } from 'react'

import { WithChildren, WithClassName } from 'common/types'
import { Typography } from 'components/designSystem/Typography/Typography'

export const LoginVariantText: FC<{} & WithChildren & WithClassName> = ({
  children,
  className,
}) => {
  return (
    <Typography className={className} fontSize={16} fontWeight={500}>
      {children}
    </Typography>
  )
}
