import React, { FC } from 'react'

import { ListItem } from 'components/designSystem/ListItem/ListItem'
import { RoundButton } from 'components/designSystem/RoundButton/RoundButton'
import { useVendorItem } from 'components/page/Boarding/SocialNetworks/VendorItem.hooks'

import { LoginIconSwitch } from './LoginIconSwitch'
import { LoginVariantText } from './LoginVariantText'
import {
  ButtonInner,
  ButtonLink,
  Link,
  LinkInline,
  ListItemInner,
  LoginOptionTextWhite,
} from './styled'

export const LoginWithVendor: FC<{
  vendor: {
    name: string
    url: string
    app: string
    icon: string
    providerName: string
  }
  variant: 'in-list' | 'in-button' | 'inline'
  onVendorClick?: (vendorName: string) => void
}> = (props) => {
  const { vendor, variant } = props
  const { isVkConnect, onVendorClick, loading } = useVendorItem(vendor)

  const iconElement = (
    <div style={{ width: 20, height: 20 }}>
      <LoginIconSwitch name={vendor.name} />
    </div>
  )

  const linkProps = {
    target: '_self',
    href: isVkConnect ? '#' : vendor.url,
    ['data-name']: vendor.name,
    onClick: (event) => {
      props.onVendorClick?.(vendor.name)
      onVendorClick(event)
    },
  }

  switch (variant) {
    case 'in-list':
      return (
        <Link {...linkProps}>
          <ListItem size="M">
            <ListItemInner>
              <RoundButton size="S" interactive={false}>
                {iconElement}
              </RoundButton>
              <LoginVariantText>{vendor.providerName}</LoginVariantText>
            </ListItemInner>
          </ListItem>
        </Link>
      )

    case 'in-button':
      return (
        <ButtonLink {...linkProps} loading={loading}>
          <ButtonInner>
            <LoginIconSwitch name={vendor.name} />
            <LoginOptionTextWhite>{vendor.providerName}</LoginOptionTextWhite>
          </ButtonInner>
        </ButtonLink>
      )

    case 'inline':
      return (
        <LinkInline {...linkProps}>
          <RoundButton size="S">{iconElement}</RoundButton>
        </LinkInline>
      )

    default:
      return null
  }
}
